import React from "react"
import "../global.css"
import End from "../subpages/End";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SeoHelmet from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import CardBox from "../components/CardBox"

function WerfenwengCard() {
  return (
    <div>
      <SeoHelmet 
        title="Nachhaltig mit Elektromobilität im Urlaub in Salzburg - Haus Gruber Ferienwohnungen"
        description="Mit dem Konzept der sanften Mobilität und der Werfenweng Card können Sie Ihren Urlaub in Salzburg im Haus Gruber Ferienwohnungen in Werfenweng in vollen Zügen genießen."
      />
      <Header />
      <div className="container">
        <div className="flexbox">
          <h1 className="text-center my-5">Die neue Werfenweng Card<br /><small>Eine Karte, viele bunte Vorteile.</small></h1>
          <div className="row no-gutters position-relative">
            <div className="col-md-12 mb-md-0">
              <p>Als Modellort für sanfte Mobilität und mit dem Leitsatz „Alle Zeit der Welt“ sind uns zwei Dinge wichtig: Erstens, dass du als unser Gast stets die Möglichkeit hast, dich umweltbewusst fortzubewegen. Und zweitens: dass du deine wertvolle Zeit bei uns voll und ganz auskosten kannst. Deshalb haben wir eine Karte entwickelt, die dir Sommer wie Winter tolle Vorteile in Sachen sanfter Mobilität, aber auch buntem Zeitvertreib ermöglicht.</p>
            </div>
          </div>
          <div className="row no-gutters position-relative">
            <div className="col-md-12 mb-md-0">
              <h5>Ferienwohnungen Haus Gruber ist Werfenweng Card Partnerbetrieb</h5>
              <p>Und so funktioniert die neue Werfenweng Card:</p>
            </div>
          </div>
          <CardBox />
          <div className="row no-gutters position-relative">
            <div className="col-md-12 mb-md-0">
              <p><small>* Gültigkeit: die Werfenweng Card gilt für den gesamten Zeitraum deines Aufenthaltes – ab dem Anreisetag bis zum Abreisetag – und ist nicht übertragbar.</small></p>
            </div>
          </div>
          <div className="row no-gutters position-relative mt-5">
            <div className="col-md-6 mb-4 p-md-4 order-md-last">
              <StaticImage
                  src="../images/samo/ski.jpeg"
                  alt="Fang dir die schönsten Wintermomente in Werfenweng"
                  className="w-100"
                  placeholder="blurred"
                  layout="fixed"
                  width={400 }
                  height={267}
                />
            </div>
            <div className="col-md-6 p-md-4">
              <h5>Fang dir die schönsten Wintermomente in Werfenweng</h5>
              <p>Vom Schneeschuhwandern zur Pferdeschlittenfahrt – und nicht zu vergessen: zu jeder Zeit herrlich mobil. Das sind nur ein paar der bunten Attraktionen und Vorteile, zu denen dir unsere Werfenweng Card Zutritt verschafft. Erhältlich in der Basic- (100 Punkte) oder Plusvariante (300 Punkte) und für jeden winterlichen Spaß zu haben.</p>
            </div>
            
          </div>
          <div className="row no-gutters position-relative">
            <div className="col-md-6 mb-4 p-md-4">
                <StaticImage
                  src="../images/samo/wandern.jpg"
                  alt="Erlebe die vielen Seiten des Sommers in Werfenweng"
                  className="w-100"
                  placeholder="blurred"
                  layout="fixed"
                  width={400 }
                  height={267}
                />
              </div>
            <div className="col-md-6 p-md-4">
              <h5>Erlebe die vielen Seiten des Sommers in Werfenweng</h5>
              <p>Immer schön umweltfreundlich unterwegs, zwischendurch schwimmen im malerischen Wengsee oder wandern in Begleitung von ein paar kuscheligen Alpakas. Unsere Werfenweng Card ist in einer Basic- (200 Punkte) oder Plusversion (400 Punkte) erhältlich und dein Schlüssel zu vielen bunten Vorteilen, die den Urlaub bei uns noch schöner machen.</p>
            </div>
          </div>
          <div className="row no-gutters position-relative  pb-5">
            <div className="col-md-12 mb-md-0">
              <p>Weitere Informationen zum gesamten Leistungsumfang sowie aktuelle Preise finden Sie auf der Homepage des Tourismusverband Werfenweng unter <a href="https://www.werfenweng.eu/urlaub/werfenweng-card/" target="_blank" rel="noreferrer">https://www.werfenweng.eu/urlaub/werfenweng-card/</a></p>
            </div>
          </div>
          
        </div>
      </div>
      <End/>
      <Footer/>
    </div>
  )
}

export default WerfenwengCard
