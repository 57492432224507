import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressCard,
} from '@fortawesome/free-regular-svg-icons'

import {
  faPersonWalkingLuggage,
  faHeartCircleBolt,
  faPersonHiking,
} from '@fortawesome/free-solid-svg-icons'

const H5 = styled.h5`
position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 5%;
    bottom: -2px;
    width: 60px;
    height: 10px;
    transform: skew(-12deg) translateX(-50%);
    background: #FFCD6C;
    z-index: -1;
  }
`;

function CardBox() {
  return (  
    <div class="row no-gutters py-3 row-cols-1 row-cols-lg-4">
        <div class="feature col px-2">
          <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
            <FontAwesomeIcon icon={faPersonWalkingLuggage} size="4x" color="#FFCD6C" />
          </div>
          <H5 class="fs-2">1 - Buchen</H5>
          <p>Buche deinen Sommer- oder Winterurlaub im Haus Gruber und bahne dir deinen Weg zu uns nach Werfenweng.</p>
        </div>

        <div class="feature col px-2">
          <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
          <FontAwesomeIcon icon={faAddressCard} size="4x" color="#FFCD6C"  />
          </div>
          <H5 class="fs-2">2 -Karte wählen</H5>
          <p>Wähle zwischen Werfenweng Card oder Werfenweng Card Plus. Kaufe die entsprechende Card direkt online oder im Tourismusverband Werfenweng.</p>
        </div>

        <div class="feature col px-2">
          <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
          <FontAwesomeIcon icon={faHeartCircleBolt} size="4x" color="#FFCD6C"  />
          </div>
          <H5 class="fs-2">3 - Erleben</H5>
          <p>Sobald du deine persönliche Werfenweng Card in den Händen hältst, kannst du alle Mobilitätsleistungen während deines Urlaubes* ohne Punkteabzug genießen.</p>
        </div>

        <div class="feature col px-2">
          <div class="feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-2 mb-3">
          <FontAwesomeIcon icon={faPersonHiking} size="4x" color="#FFCD6C"  />
          </div>
          <H5 class="fs-2">4 - Noch mehr erleben</H5>
          <p>Zusätzlich zu den inkludierten Mobilitäts­leistungen kannst du im Tausch gegen eine gewisse Punkteanzahl noch viele weitere tolle Leistungen buchen.</p>
        </div>
      </div>
  
  )
}

export default CardBox

